import { AppState, ProbeArgument } from '../createStore';
import {
  clearSessionStorage,
  initApp,
  saveAddressToServer,
  saveStateToSessionStorage,
  setIsLoadingAddressesFromServer,
  setIsUserLoggedIn,
  setSavedAddresses,
  setSignedInstance,
  userLoggedIn,
  changeLocation,
  setIsLoadingLocationFromServer,
  setOrganizationFull,
  setLocations,
  closeModal,
  setIsMAInstalled,
  setIsRewardInstalled,
  runSideEffectsAfterFirstRender,
  setIsLocationPicked,
  setLoadingLocationsSettingsFromServer,
  setLoyaltyRewards,
  setLoyaltyAccount,
  setLoyaltyProgram,
  goToLoyaltyPage,
  setLoyaltyEarningRules,
  openModal,
  setLockedDineInLocation,
  setPaymentMethods,
  setLoadingPaymentMethods,
  setSiteLanguage,
  setExperiments,
  setOrderPacingLevel,
  getCurrentPacingLevel,
  removeDispatchTypeFromURL,
  setIsDineInClosedInQRLocation,
} from './session.actions';
import { clearCart } from '../cart/cart.actions';
import { EMPTY_CATALOG, Modals, SESSION_STORAGE_KEY } from '../../core/constants';
import moment from 'moment-timezone';
import { AddressesWeb, ListResponse } from '@wix/ambassador-addresses-web/http';
import {
  initCheckout,
  setCheckoutStep,
  setDeliveryAddress,
  setDispatchType,
  setMemberContactLoading,
  setMembersAPiContact,
  setSelectedAddressId,
  setTip,
} from '../checkout/checkout.actions';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { Dispatch } from 'redux';
import {
  Action,
  extractLocalizedString,
  getOrganizationAndMenu,
  hasLoyaltySupport,
  isAddress,
  DINE_IN,
  Catalog,
  ORDERS_APP_ID,
} from '@wix/restaurants-client-logic';
import { ChangeLocationPayload, SaveAddressToServerPayload } from './session.actions.types';
import { getMemberContactDetails } from '../checkout/checkout.probe.utils';
import { convertMembersAddressToOloAddress, getUpdateAddressObjectPaths } from '../../core/logic/addressLogic';
import { getLocationsSettings } from '../../core/oloApi';
import { fetchOrderPacingCurrentLevel } from '../../core/logic/orderPacingLogic';
import { getBaseUrlForMappedServices } from '../../core/logic/urlLogic';
import { componentSettings } from '../../components/MainPage/componentSettings';
import { LoyaltyRewards } from '@wix/ambassador-loyalty-rewards/http';
import { LoyaltyAccounts } from '@wix/ambassador-loyalty-accounts/http';
import { LoyaltyPrograms } from '@wix/ambassador-loyalty-programs/http';
import { LoyaltyCalculator } from '@wix/ambassador-loyalty-calculator/http';
import { MA_APP_IDS } from '@wix/members-area-app-definitions';
import _ from 'lodash';
import { setSelectedAddress } from '../addressInformationForm/addressForm.actions';
import { getDineInInfo, isPickupUnavailableATM } from '../../core/logic/dispatchLogic';
import { getOpenLocations, selectOpenLocations } from '../selectors/locationsSelector';
import {
  extractInstanceFromFlowAPI,
  getPaymentMethodsAndSetLoading,
  OrganizationDeprecated,
} from '../../components/MainPage/controller';
import type { PartialLocation } from '../../core/oloApi';
import { initSession } from '@wix/bi-logger-olo-client/v2';
import { fetchCatalog } from '../../core/catalogApi';

async function getLoggedInUserEmail(flowAPI: ControllerFlowAPI): Promise<string | undefined> {
  try {
    return await flowAPI.controllerConfig.wixCodeApi.user.currentUser.getEmail();
  } catch (e) {
    return undefined;
  }
}

function getIsLocationClosed(locations: PartialLocation[] = [], locationId: string | undefined): boolean {
  return _.every(locations, (location) => location?.currentLocationId !== locationId);
}

async function updateOrderPacingCurrentLevel(
  flowAPI: ControllerFlowAPI,
  state: AppState,
  dispatch: Function,
  locationId?: string,
): Promise<void> {
  const { experiments } = flowAPI;
  const isOrderPacingEnabled = !experiments.enabled('specs.restaurants.disable-order-pacing');
  if (isOrderPacingEnabled) {
    const pickedLocation = locationId || state.session?.restaurant?.currentLocationId;
    const signedInstance = getSignedInstance(flowAPI);
    const currentPacingLevel = await fetchOrderPacingCurrentLevel(signedInstance, pickedLocation, flowAPI.fedops);
    dispatch(setOrderPacingLevel({ value: currentPacingLevel }));
  }
}

export default function sessionProbe({ onAction, onActionOnce }: ProbeArgument) {
  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const { experiments } = flowAPI;
    const state = getState();
    const isLoyaltyEnabled = hasLoyaltySupport(state.session.charges);
    const signedInstance = getSignedInstance(flowAPI);
    const isMultiLocation = state.session.isMultiLocation;
    const restaurant = state.session.restaurant;
    const restaurantId = restaurant.id;

    dispatch(setExperiments({ experiments: experiments.all() }));
    const baseUrlForMappedServices = getBaseUrlForMappedServices({
      websiteUrl: flowAPI.controllerConfig.wixCodeApi.location.baseUrl,
      environment: flowAPI.environment,
    });

    flowAPI.bi &&
      flowAPI.bi.report(
        initSession({
          projectName: undefined,
          locationGuid: restaurant.currentLocationId,
        } as any),
      );

    const isLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';
    dispatch(
      setIsUserLoggedIn({
        isLoggedIn,
        loggedInUserEmail: await getLoggedInUserEmail(flowAPI),
      }),
    );

    const { shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch, state);
    }

    if (!flowAPI.environment.isSSR) {
      flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => {
        dispatch(userLoggedIn());
      });
    }
    const dispatchTypeQuery = flowAPI.controllerConfig.wixCodeApi.location.query
      ? flowAPI.controllerConfig.wixCodeApi.location.query.dispatchType
      : undefined;

    const locationId = flowAPI.controllerConfig.wixCodeApi.location.query
      ? flowAPI.controllerConfig.wixCodeApi.location.query.locationId
      : undefined;

    if (isMultiLocation && _.isEmpty(getState().session.locations)) {
      dispatch(setLoadingLocationsSettingsFromServer({ isLoadingLocationsSettingsFromServer: true }));
      const locations = await getLocationsSettings(signedInstance, restaurantId, baseUrlForMappedServices);
      dispatch(setLoadingLocationsSettingsFromServer({ isLoadingLocationsSettingsFromServer: false }));
      dispatch(setLocations({ locations }));

      // In case that the location is unavailable - unpick it
      if (locationId) {
        const openLocations = selectOpenLocations(getState());
        const isLocationClosed = getIsLocationClosed(openLocations, locationId);

        if (isLocationClosed && dispatchTypeQuery !== DINE_IN) {
          if (openLocations.length > 0) {
            dispatch(changeLocation({ locationId: _.get(openLocations, '[0].currentLocationId') }));
          } else {
            dispatch(setIsLocationPicked({ value: false }));
          }
        }
      }
      if (dispatchTypeQuery === DINE_IN) {
        if (!getDineInInfo(restaurant)?.enabled) {
          dispatch(setIsDineInClosedInQRLocation({ value: true }));
          dispatch(openModal({ modal: Modals.DINE_IN_CLOSED }));
        } else {
          if (locationId && !isPickupUnavailableATM(restaurant.deliveryInfos, restaurant)) {
            dispatch(setLockedDineInLocation({ locationId }));
            dispatch(setDispatchType({ dispatchType: dispatchTypeQuery }));
            if (
              getOpenLocations(
                locations,
                state.session.restaurant.products,
                state.session.paymentMethods.length > 0,
                state.session.restaurant,
              ).some((loc) => loc.currentLocationId === locationId)
            ) {
              dispatch(openModal({ modal: Modals.ADDRESS_INFORMATION_MODAL }));
            }
          }
        }
      }
    }

    await fetchLoyaltyDataIfNeeded(flowAPI, dispatch, isLoyaltyEnabled, isLoggedIn);
    if (experiments.enabled('specs.restaurants.delaySetPayments')) {
      const { instanceId, appDefId: appId } = extractInstanceFromFlowAPI(flowAPI);
      const locale = state.session.restaurant?.locale || 'en_US';
      const paymentMethods = await getPaymentMethodsAndSetLoading(appId, instanceId, locale, (loading) =>
        dispatch(setLoadingPaymentMethods({ loading })),
      );
      dispatch(setPaymentMethods({ paymentMethods }));
      dispatch(setLoadingPaymentMethods({ loading: false }));
    }

    dispatch(setSiteLanguage({ siteLanguage: flowAPI.environment.language }));
    updateOrderPacingCurrentLevel(flowAPI, getState(), dispatch);
  });

  onAction(initCheckout.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const isLoyaltyEnabled = hasLoyaltySupport(state.session.charges);
    const isLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';
    await fetchLoyaltyDataIfNeeded(flowAPI, dispatch, isLoyaltyEnabled, isLoggedIn);
    const { shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch, getState());
    }
  });

  onActionOnce(userLoggedIn.toString(), async (action, getState, dispatch, { flowAPI }) => {
    dispatch(setCheckoutStep({ step: 'address-information' }));
    dispatch(
      setIsUserLoggedIn({
        isLoggedIn: true,
        loggedInUserEmail: await getLoggedInUserEmail(flowAPI),
      }),
    );
    dispatch(setSignedInstance({ signedInstance: getSignedInstance(flowAPI) }));
    const { shouldInitMemberAddresses } = await checkMembersAreaPrerequisits(flowAPI);

    if (shouldInitMemberAddresses) {
      await initMemberAddresses(flowAPI, dispatch, getState());
    }
    dispatch(setMemberContactLoading({ loading: true }));
    const contact = await getMemberContactDetails(flowAPI);
    if (contact) {
      dispatch(setMembersAPiContact({ contact }));
    }
    dispatch(setMemberContactLoading({ loading: false }));

    const isLoyaltyEnabled = hasLoyaltySupport(getState().session.charges);
    await fetchLoyaltyAccount(flowAPI, dispatch, isLoyaltyEnabled);
  });

  onAction(saveStateToSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    const { cart, checkout, addressForm } = getState();
    const { orderItems, coupon, comment } = cart;
    const { checkoutStep, contact, loyaltyPointsToRedeem, selectedAddressId, deliveryProvider, calculatedFees } =
      checkout;
    const { selectedAddressOption } = addressForm;
    const timestamp = moment().valueOf();

    flowAPI.controllerConfig.platformAPIs.storage.session.setItem(
      SESSION_STORAGE_KEY,
      JSON.stringify({
        orderItems,
        coupon,
        comment,
        dispatch: checkout.dispatch,
        checkoutStep,
        contact,
        selectedAddressOption,
        timestamp,
        loyaltyPointsToRedeem,
        selectedAddressId,
        deliveryProvider,
        calculatedFees,
      }),
    );
  });

  onAction(clearSessionStorage.toString(), (action, getState, dispatch, { flowAPI }) => {
    flowAPI.controllerConfig.platformAPIs.storage.session.removeItem(SESSION_STORAGE_KEY);
  });

  onAction(
    saveAddressToServer.toString(),
    async (action: Action<SaveAddressToServerPayload>, getState, dispatch, { flowAPI }) => {
      const signedInstance = getSignedInstance(flowAPI);
      const headers = { Authorization: signedInstance };
      const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
      const { address, addressId, setAsDefault } = action.payload;

      dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));

      if (addressId) {
        const paths = getUpdateAddressObjectPaths(address);
        if (setAsDefault) {
          paths.push('setAsDefault');
        }
        await addressesService.update({ address: { id: addressId, ...address }, setAsDefault, fieldMask: { paths } });
        dispatch(setSelectedAddressId({ id: addressId }));
      } else {
        const { id } = await addressesService.create({ address, setAsDefault });
        dispatch(setSelectedAddressId({ id }));
      }
      await getUserAddresses(flowAPI, dispatch);
      dispatch(closeModal({ modal: Modals.ADDRESS_SELECTION }));
    },
  );

  onAction(runSideEffectsAfterFirstRender.toString(), async (action: Action<any>, getState, dispatch, { flowAPI }) => {
    const membersAreaAppDefId = '14cffd81-5215-0a7f-22f8-074b0e2401fb';
    const restaurant = getState().session.restaurant;
    const myRewardsAppDefId = '16ed1ac6-01cb-4fb6-a59e-c215cce8fdf6';
    const myRewardsSectionId = 'My Rewards';

    flowAPI.environment.isViewer &&
      flowAPI.controllerConfig.wixCodeApi.window.trackEvent('ViewContent', {
        origin: 'Restaurants',
        name: extractLocalizedString(restaurant.title, restaurant.locale),
      });

    try {
      const isMAInstalled = await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
        appDefinitionId: membersAreaAppDefId,
        sectionId: 'member_info',
      });

      dispatch(setIsMAInstalled({ isMAInstalled }));
    } catch (e) {
      console.log('Failed to set isMAInstalled', e);
    }
    try {
      const isRewardInstalled = await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
        appDefinitionId: myRewardsAppDefId,
        sectionId: myRewardsSectionId,
      });

      dispatch(setIsRewardInstalled({ isRewardInstalled }));
    } catch (e) {
      console.log('Failed to set isRewardInstalled', e);
    }
  });

  onAction(
    changeLocation.toString(),
    async (action: Action<ChangeLocationPayload>, getState, dispatch, { flowAPI }) => {
      /**
       *  - check if we need to change the location
       * No:
       * 1. put the location id in the url
       * 2. updated the state to indicate the user selected a location
       * Yes:
       * 1. indicate fetching menu
       * 2. fetch new menu and org and replace the old one
       * 3. clear cart
       * 4. put the location id in the url
       * 5. navigate to menus
       */
      const restaurant = getState().session.restaurant;
      const { experiments } = flowAPI;
      const isCatalogsV3 = experiments.enabled('specs.restaurants.catalogs-v3-migration');

      if (restaurant.currentLocationId === action.payload.locationId) {
        flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
          locationId: restaurant.currentLocationId,
        });
        dispatch(setIsLocationPicked({ value: true }));
        return;
      }

      const signedInstance = getSignedInstance(flowAPI);

      dispatch(setIsLoadingLocationFromServer({ isLoadingLocationFromServer: true }));

      let organizationFull: OrganizationDeprecated | null | undefined;
      let catalog: Catalog;
      if (isCatalogsV3) {
        [organizationFull, catalog] = await Promise.all([
          getOrganizationAndMenu(signedInstance, action.payload.locationId),
          fetchCatalog(flowAPI, action.payload.locationId),
        ]);
      } else {
        organizationFull = await getOrganizationAndMenu(signedInstance, action.payload.locationId);
        catalog = EMPTY_CATALOG;
      }

      if (organizationFull) {
        dispatch(clearCart());
        dispatch(setTip({ tip: undefined }));
        dispatch(setOrganizationFull({ organizationFull: { ...organizationFull, catalog } }));
        if (organizationFull.restaurant.currentLocationId) {
          flowAPI.controllerConfig.wixCodeApi.location.queryParams.add({
            locationId: organizationFull.restaurant.currentLocationId,
          });
          updateOrderPacingCurrentLevel(flowAPI, getState(), dispatch, organizationFull.restaurant.currentLocationId);
          dispatch(setIsLocationPicked({ value: true }));
        }
      }
      dispatch(setIsLoadingLocationFromServer({ isLoadingLocationFromServer: false }));
    },
  );

  onAction(
    removeDispatchTypeFromURL.toString(),
    async (action: Action<ChangeLocationPayload>, getState, dispatch, { flowAPI }) => {
      flowAPI.controllerConfig.wixCodeApi.location.queryParams.remove(['dispatchType']);
    },
  );

  onAction(goToLoyaltyPage.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const santaMembersAppDefinitionId = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

    const api = await flowAPI.controllerConfig.wixCodeApi.site.getPublicAPI(santaMembersAppDefinitionId);

    if (api && api.navigateToSection) {
      api.navigateToSection({
        appDefinitionId: MA_APP_IDS.MY_REWARDS.appDefinitionId,
        sectionId: MA_APP_IDS.MY_REWARDS.pageId,
        memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
      });
    }
  });

  onAction(openModal.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const modalName = _.get(action, 'payload.modal');
    switch (modalName) {
      case Modals.ADDRESS_INFORMATION_MODAL:
        updateOrderPacingCurrentLevel(flowAPI, getState(), dispatch);
        break;
      default:
        return;
    }
  });

  onAction(getCurrentPacingLevel.toString(), async (action, getState, dispatch, { flowAPI }) => {
    updateOrderPacingCurrentLevel(flowAPI, getState(), dispatch, action?.payload);
  });
}

async function checkMembersAreaPrerequisits(flowAPI: ControllerFlowAPI) {
  const { isViewer } = flowAPI.environment;
  const hasMembersAreaIntegration = flowAPI.settings.get(componentSettings.hasMembersAreaIntegration);
  const isMembersAddressEnabled = hasMembersAreaIntegration;
  const isUserLoggedIn = flowAPI.controllerConfig.wixCodeApi.user.currentUser.role !== 'Visitor';

  return {
    isMembersAddressEnabled,
    isUserLoggedIn,
    isViewer,
    shouldInitMemberAddresses: isMembersAddressEnabled && isUserLoggedIn && isViewer,
  };
}

export function getSignedInstance(flowAPI: ControllerFlowAPI) {
  return (
    flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(ORDERS_APP_ID) || flowAPI.controllerConfig.appParams.instance
  );
}

async function fetchMemberAddressesData(flowAPI: ControllerFlowAPI) {
  const signedInstance = getSignedInstance(flowAPI);
  const headers = { Authorization: signedInstance };
  const addressesService = AddressesWeb('/_api/addresses-web').Addresses()(headers);
  flowAPI.fedops.interactionStarted('list-uou-addresses');
  const { addresses = [], defaultAddressId }: ListResponse = await addressesService.list({});
  flowAPI.fedops.interactionEnded('list-uou-addresses');
  return { addresses: addresses.filter((a) => Boolean(a.addressLine1)), defaultAddressId };
}

async function getUserAddresses(flowAPI: ControllerFlowAPI, dispatch: Dispatch<Action<any>>) {
  try {
    dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));
    const { addresses, defaultAddressId } = await fetchMemberAddressesData(flowAPI);
    dispatch(setSavedAddresses({ addresses, defaultAddressId }));
    return { addresses, defaultAddressId };
  } catch (e) {
    return { addresses: [] };
  } finally {
    dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));
  }
}

async function initMemberAddresses(flowAPI: ControllerFlowAPI, dispatch: Dispatch<Action<any>>, state: AppState) {
  const { experiments } = flowAPI;
  if (experiments.enabled('specs.restaurants.olo-solve-delivery-infinite-loader-bug')) {
    const { addresses, defaultAddressId } = await getUserAddresses(flowAPI, dispatch);

    const defaultAddress = addresses?.find((address) => address.id === defaultAddressId);

    const hasAddress = state.checkout.dispatch.type === 'delivery' && isAddress(state.checkout.dispatch.address);

    if (defaultAddress && defaultAddress.addressLine1 && !hasAddress) {
      dispatch(setSelectedAddressId({ id: defaultAddressId }));
      dispatch(setSelectedAddress({ address: convertMembersAddressToOloAddress(defaultAddress) }));
      dispatch(
        setDeliveryAddress({
          address: convertMembersAddressToOloAddress(defaultAddress),
        }),
      );
    }
  } else {
    dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: true }));
    const { addresses, defaultAddressId } = await fetchMemberAddressesData(flowAPI);
    dispatch(setSavedAddresses({ addresses, defaultAddressId }));
    dispatch(setIsLoadingAddressesFromServer({ isLoadingAddressesFromServer: false }));

    const defaultAddress = addresses.find((address) => address.id === defaultAddressId);

    const hasAddress = state.checkout.dispatch.type === 'delivery' && isAddress(state.checkout.dispatch.address);

    if (defaultAddress && defaultAddress.addressLine1 && !hasAddress) {
      dispatch(setSelectedAddressId({ id: defaultAddressId }));
      dispatch(setSelectedAddress({ address: convertMembersAddressToOloAddress(defaultAddress) }));
      dispatch(
        setDeliveryAddress({
          address: convertMembersAddressToOloAddress(defaultAddress),
        }),
      );
    }
  }
}

async function fetchLoyaltyDataIfNeeded(
  flowAPI: ControllerFlowAPI,
  dispatch: Dispatch<Action<any>>,
  isLoyaltyEnabled: boolean,
  isLoggedIn: boolean,
) {
  const appDefinitionId = '553c79f3-5625-4f38-b14b-ef7c0d1e87df';
  const sectionId = 'Loyalty';
  let isLoyaltyAppInstalled = false;
  try {
    isLoyaltyAppInstalled = await flowAPI.controllerConfig.wixCodeApi.site.isAppSectionInstalled({
      appDefinitionId,
      sectionId,
    });
  } catch (e) {
    console.log('Failed to get isLoyaltyAppInstalled', e);
  }

  if (isLoyaltyEnabled && isLoyaltyAppInstalled) {
    const headers = { Authorization: getSignedInstance(flowAPI) };
    try {
      const { rewards: loyaltyRewards = [] } = await LoyaltyRewards('/_api/loyalty-rewards')
        .LoyaltyRewards()(headers)
        .listRewards({});

      dispatch(setLoyaltyRewards({ loyaltyRewards }));

      const { loyaltyProgram } = await LoyaltyPrograms('/_api/loyalty-programs')
        .LoyaltyPrograms()(headers)
        .getLoyaltyProgram({});

      if (loyaltyProgram) {
        dispatch(setLoyaltyProgram({ loyaltyProgram }));
      }

      const { rules: earningRules } = await LoyaltyCalculator('/_api/loyalty-calculator')
        .LoyaltyCalculator()(headers)
        .listEarningRules({
          triggerActivityType: 'wix-restaurants/orderSubmitted',
          triggerAppId: ORDERS_APP_ID,
        });

      if (earningRules) {
        dispatch(setLoyaltyEarningRules({ earningRules }));
      }

      if (isLoggedIn) {
        await fetchLoyaltyAccount(flowAPI, dispatch, isLoyaltyEnabled);
      }
    } catch (e) {}
  }
}

async function fetchLoyaltyAccount(
  flowAPI: ControllerFlowAPI,
  dispatch: Dispatch<Action<any>>,
  isLoyaltyEnabled: boolean,
) {
  if (isLoyaltyEnabled) {
    const headers = { Authorization: getSignedInstance(flowAPI) };

    const { account: loyaltyAccount = {} } = await LoyaltyAccounts('/_api/loyalty-accounts')
      .LoyaltyAccounts()(headers)
      .getCurrentMemberAccount({});

    dispatch(setLoyaltyAccount({ loyaltyAccount }));
  }
}
